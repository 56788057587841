@charset "UTF-8";

@import "definitions";


/* common
====================================================================================================================================== */

/* リンク */
a{
  color: $link_color;
  font-weight: bold;
  text-decoration: underline;
  transition: .5s;
  &.outerlink{
    &:after{
      content: "";
      display: inline-block;
      width: 0.76em;
      aspect-ratio: 1;
      background: url(../images/icon_external_link.svg) no-repeat left center;
      background-size: contain;
      margin-left: 0.3em;
    }
  }
}

@media (hover: hover) {
  a:hover {
    text-decoration: none;
    color: $hover_color;
  }
}

/*非表示*/
.none {
  display: none !important;
}

/* PC、スマホ振り分け */
.sp, .tb, .sp_only{
  display: none !important;
}


img{
  &.filer_image{
    &.none{
      display: inline !important;
    }
  }
}

.hide {
  display: none;
}

img {
  height: auto;
}

/* スクロールして画面に入ったらフェードイン */
.scroll_fadein { opacity: 0; translate: 0 50px; transition: .7s ease-in-out;}
.scroll_fadein.window_in { opacity: 1; translate: 0 0;}
.scroll_opacity { opacity: 0; transition: 1.2s ease-in-out;}
.scroll_opacity.window_in { opacity: 1;}


/*左寄せ・右寄せ対策*/

img.left_align_img {
  margin-right: 15px;
}

img.right_align_img {
  margin-left: 15px;
}

.flex{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.white{
  color: #fff;
}
.bg_white{
  background-color: #fff;
}

/*画像背景のボタン*/

.bg_img_btn a,
a.bg_img_btn {
  display: block;
  background: no-repeat;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

/*center button list*/

.btn_center_box {
  clear: both;
  overflow: hidden;
  width: 100%;
  margin: 0 0 10px;
}

.btn_center_box:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.btn_center_box ul {
  position: relative;
  left: 50%;
  float: left;
  margin: 0;
  overflow: visible;
  list-style: none;
  list-style-position: inside;
}

.btn_center_box ul:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.btn_center_box li {
  position: relative;
  left: -50%;
  float: left;
  list-style: none;
  display: inline;
}

.btn_center_box li:first-child {
  margin: 0;
}

.btn_center_box li a {
  margin: 0 10px 10px;
}

/*list*/

#conts ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5em;
  > li {
    position: relative;
    padding-left: 0.8em;
    margin-bottom: 0.5em;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0.5lh;
      translate: 0 -50%;
      background-color: $origin_color;
      width: 0.35em;
      height: 0.35em;
    }
  }
}

#conts ul.sitemap {
  margin-bottom: 0;
}

#conts ol {
  counter-reset: ordered_num;
  background: none;
  overflow: hidden;
  margin-bottom: 1.5em;

  > li {
    padding-left: 2em;
    margin-bottom: 0.5em;
    position: relative;
    &:before {
      counter-increment: ordered_num;
      content: counter(ordered_num);
      background-color: $origin_color;
      display: grid;
      place-items: center;
      color: #fff;
      font-family: $font_teko;
      font-size: 1.15rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.03em;
      width: 1.275em;
      aspect-ratio: 1/1;
      position: absolute;
      left: 0;
      top: 1rem;
      padding-top: 0.2em;
      translate: 0 -50%;
    }
  }
  &.type02{
    > li{
      position: static;
      text-indent: -1.7em;
      padding-left: 1.7em;
      margin-bottom: 0.4em;
      &:before{
        content: "("counter(ordered_num)")";
        display: inline;
        background: none;
        font-family: inherit;
        font-size: inherit;
        font-weight: normal;
        line-height: inherit;
        color: inherit;
        width: auto;
        aspect-ratio: initial;
        position: static;
        padding: 0;
        margin-right: 0.4em;
        translate: 0;
      }
    }
  }
}

#conts,.contents{

  ul.caution {
    margin-bottom: 2.5em;
  }
  
  ul.caution li {
    margin-bottom: 0;
  }
  
  ul.caution li:before {
    content: "※";
  }
  
  ul.plane,
  ol.plane,
  ul.plane li,
  ol.plane li {
    list-style-type: none!important;
    padding: 0;
  }
  
  ul.plane li:before,
  ol.plane li:before {
    display: none;
  }
  
}

/*news list*/

#conts .article_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  padding: 0;
  margin-bottom: 3em;
  li {
    border: {
      top: 1px solid $origin_color;
      bottom: 1px solid $origin_color;
    }
    padding: 7em 0 2.7em;
    margin: 0 0 -1px;
    line-height: 1.8;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &:before{
      display: none;
    }
    p{
      margin-bottom: 0;
    }
    .tag{
      background-color: $origin_color;
      font-family: $font_teko;
      font-weight: 500;
      line-height: 1;
      text-align: center;
      color: #fff;
      letter-spacing: 0.01em;
      width: 3.5em;
      aspect-ratio: 1/1;
      position: absolute;
      top: 0;
      right: 0;
      display: grid;
      place-items: center;
    }
  
    time{
      display: block;
      font-size: 0.85rem;
      line-height: 1;
      margin-bottom: 0.8em;
    }
    a{
      font-weight: normal;
    }
  }
  
}

/*default heading*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0 0 0.8em;
  line-height: 1.7;
}

h1.page_title {
  font-size: 2.55rem;
  text-align: center;
  padding: 1.2em 20px;
  margin-bottom: 0;
  .en, .ja, .date{
    display: block;
  }
  .en{
    font-family: $font_teko;
    font-size: 2em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.01em;
    margin-bottom: -0.06em;
  }
  .ja{
    font-size: 0.6em;
    margin-bottom: -1em;
  }
  .date{
    font-size: 0.5em;
    line-height: 1;
    margin-bottom: 0.4em;
  }
  &.type2{
    .en{
      font-size: 0.7em;
    }
    .ja{
      font-size: 1.2em;
      margin-bottom: -0.5em;
    }
  }
}

h2 {
  font-size: 1.7rem;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 1px solid $origin_color;
  margin-bottom: 1.1em;
  &.simple{
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0.5em;
  }
  &.member_data_title{
    display: flex;
    align-items: center;
    gap: 2em;
    font-size: 1.275rem;
    line-height: 1;
    padding: 0;
    border: none;
    .num, .en{
      font-family: $font_teko;
      font-weight: 500;
    }
    .num{
      font-size: 9.8em;
      white-space: nowrap;
      letter-spacing: 0.05em;
    }
    .main_text{
      margin-top: -1em;
    }
    .en{
      font-size: 2.5em;
      letter-spacing: 0.06em;
      margin-bottom: 0.2em;
    }
    .en, .ja{
      display: block;
    }
  }
}

h3 {
  font-size: 1.4875rem;
  text-align: left;
  margin-bottom: 0.8em;
  &.bar{
    font-size: 1.275rem;
    background-color: $origin_color;
    padding: 0.25em 0.65em;
    color: #fff;
    &.bg_blue{
      background-color: $blue;
    }
  }
  &.interview_index{
    font-size: 1.7rem;
    padding-left: 2.9em;
    position: relative;
    margin-bottom: 0.5em;
    &:before{
      content: "";
      display: block;
      height: 1px;
      width: 2.2em;
      background-color: $origin_color;
      position: absolute;
      top: 0.5lh;
      left: 0;
    }
  }
}

h4 {
  font-size: 1.16875rem;
  text-align: left;
  padding-left: 0.95em;
  margin-bottom: 0.5em;
  position: relative;
  &:before{
    content: "";
    display: block;
    width: 0.53em;
    aspect-ratio: 1/1;
    background-color: $origin_color;
    position: absolute;
    top: 0.5lh;
    left: 0;
    translate: 0 -50%;
  }
}

h5 {
  font-size: 1.1875rem;
  text-align: left;
  margin-bottom: 0.5em;
}


/*hr*/
hr {
  height: 1px;
  border-bottom: 1px solid $origin_color;
  background: none;
  margin: 2em 0;
  overflow: hidden;
}

/*段落*/

table p {
  margin-bottom: 0;
}

/*カラー*/
.blue{ color: $blue; }
.red { color: $red; }

/*マーカー*/
.marker{
  font-weight: bold;
  padding-bottom: 0.2em;
  background: linear-gradient(transparent 65%, rgba($color: $blue, $alpha: 0.35) 0%);
}

/*画像*/

.wide_photo_image {
  position: relative;
  margin: 4.3em 0 0;
}

.wide_photo_image.in {
  margin: 3em 0 4em;
}

.wide_photo_image img {
  width: 100%;
  margin: 0;
}

.wide_photo_image .caption {
  color: #fff;
  position: absolute;
  right: 2.5%;
  bottom: 2%;
  font-size: 0.78em;
}

/*container*/

.section_box {
  display: flow-root;
  margin-bottom: 70px;
  .section_box{
    margin-bottom: 40px;
  }
  .img_box, figure{
    text-align: center;
    margin-bottom: 20px;
  }
  *:last-child{
    margin-bottom: 0 !important;
  }
}

/*3列ボックス*/
.box_3_column{
  overflow: hidden;
  margin: 0 -1.5% 1.5em;

  .box{
    float: left;
    width: 33.3333333%;
    padding: 0 1.5%;
    margin-bottom: 2em;
  }
}
/*2列ボックス*/
.box_2_column{
  overflow: hidden;
  margin: 0 -1.5% 1.5em;

  .box{
    float: left;
    width: 50%;
    padding: 0 1.5%;
    margin-bottom: 3%;
  }
}

/*索引*/
.list_box {
  margin-bottom: 2.3em;

  a {
    color: $origin_color;
    text-decoration: none;
    .title_area{
      padding-right: 2em;
      background: url(../images/icon_arrow_black_r_l.svg) no-repeat right 2px center;
      background-size: 1.5em auto;
      transition: .3s;
    }
    &[target="_blank"]{
      .title_area{
        background-image: url(../images/icon_external_link.svg);
        background-position: right center;
        background-size: 1.3em auto;
      }
    }
  }

  .border_box{
    padding: 50px;
    border: 1px solid $origin_color;
    display: flex;
    align-items: center;
    gap: 45px;
    background-color: #fff;
    transition: .5s;
  }
  .icon_area {
    width: 220px;
    aspect-ratio: 1/1;
    margin-bottom: 0;
    border: 1px solid $origin_color;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title_area{
    width: calc(100% - 220px - 45px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .time{
      font-size: 1rem;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 0.4em;
    }
    .title{
      font-size: 1.7rem;
      font-weight: bold;
      line-height: 1.6;
      margin-bottom: 0;
    }
  }
  .comment {
    font-size: 0.95625rem;
    line-height: 1.8;
    margin: 0.3em 0 0;
  }
}

@media (hover: hover) {
  .list_box {
    a{
      &:hover {
        .border_box{
          background-color: darken($color: #fff, $amount: 3);
        }
        .title_area{
          background-position: right center;
          .title{
            text-decoration: underline;
          }
        }
      }
    }
  }
}



/*image背景ボタンリスト*/

.bg_img_btn a {
  display: block;
  background: no-repeat;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

/*image100%*/

.img_wide {
  width: 100%;
}

/*button design*/

/*ボタン*/
.button_area{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 2.5em;
  a, button{
    display: block;
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    padding: 0.6em 1.4em;
    border: 1px solid $origin_color;
    background-color: $origin_color;
    width: auto;
    min-width: 168px;
    color: #fff;
    text-decoration: none;
    transition: .3s;
    position: relative;
    overflow: hidden;
    &:before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 150%;
      height: 100%;
      background-color: #fff;
      translate: -100%;
      transform: skew(-20deg);
      transform-origin: right top;
      transition: .4s;
    }
    > span{
      display: inline-block;
      padding: 0 1.6em 0 0.5em;
      background: url(../images/icon_arrow_white_r.svg) no-repeat right center;
      background-size: 1rem auto;
      position: relative;
      z-index: 1;
      transition: .3s;
    }
    &.en{
      font-family: $font_teko;
      font-size: 1.275rem;
      font-weight: 500;
      min-width: 213px;
      letter-spacing: 0.01em;
      padding-top: 0.4em;
      padding-bottom: 0.4em;
      > span{
        padding-right: 1.2em;
      }
    }
    &.button_large{
      font-size: 1.4875rem;
      padding: 1em;
      min-width: 260px;
      > span{
        padding-right: 1.1em;
      }
    }
    &.button_back{
      > span{
        background-image: url(../images/icon_arrow_white_l.svg);
        background-position: left center;
        padding: 0 0.5em 0 1.6em;
      }
    }
  }
}

@media (hover: hover) {
  .button_area{
    a, button{
      &:hover {
        color: $origin_color;
        &:before{
          translate: 0;
        }
        > span{
          background-image: url(../images/icon_arrow_black_r.svg);
        }
      }
      &.button_white{
        &:hover {
          color: #fff;
          background-color: $origin_color;
        }
      }
      &.button_back{
        &:hover{
          > span{
            background-image: url(../images/icon_arrow_black_l.svg);
          }
        }
      }
    }
  }
}


/* ２列ボタン */
.button_2_column{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2em;

  .btn{
    display: block;
    width: 48%;
    margin-bottom: 1.1em;
    a{
      display: block;
      margin: 0;
      width: 100%;
    }
  }
}


/*table*/

.scroll_table{
  overflow: auto;
  margin-bottom: 3.5em;
  table{
    margin-bottom: 0;
  }
}
/* テーブル */
table {
  width: 100%;
  margin-bottom: 3.5em;

  caption {
    display: none;
  }
  th,td{
    border: {
      top: 1px solid $origin_color;
      bottom: 1px solid $origin_color;
    }
    padding: 1.5em 1.1em;
    vertical-align: top;
  }
  th {
    font-weight: bold;
    white-space: nowrap;
  }
  thead{
    th{
      font-size: 0.9em;
      font-weight: bold;
      padding: 0 1.3em 0.5em;
      border-top: none;
    }
  }
  tbody{
    th{
      width: 0;
      min-width: 11em;
      //word-break: keep-all;
      background-color: #f5f5f5;
      &.bg_blue{
        background-color: $blue;
        color: #fff;
      }
    }
  }
}

/* ポイントボックス
====================================================================================================================================== */
.point_box {
  border: 1px solid $origin_color;
  padding: 3em 3em 2.5em;
  margin-bottom: 1.7em;
  position: relative;
  &:before{
    content: "";
    width: 10px;
    aspect-ratio: 1/1;
    background-color: $origin_color;
    position: absolute;
    top: 0;
    left: 0;
  }
  h3{
    margin-bottom: 0.6em;
  }
  p{
    margin-bottom: 0.8em;
  }
  *:last-child{
    margin-bottom: 0;
  }
}


/*画像で見出しを作る用（スタイルを強制リセット）*/

h1.plane,
h2.plane,
h3.plane,
h4.plane,
h5.plane {
  background: none!important;
  border: none!important;
  padding-left: 0!important;
  padding-right: 0!important;
  padding-top: !important;
}

h1.plane:after,
h2.plane:after,
h3.plane:after,
h4.plane:after,
h5.plane:after {
  display: none!important;
}

h1.plane img,
h2.plane img,
h3.plane img,
h4.plane img,
h5.plane img {
  margin: 0;
  max-width: none;
}

/*adjust*/

.clear {
  clear: both;
}

.ma0 {
  margin: 0!important;
}

.mab0 {
  margin-bottom: 0!important;
}

.mab5 {
  margin-bottom: 5px!important;
}

.mab10 {
  margin-bottom: 10px!important;
}

.mab20 {
  margin-bottom: 20px!important;
}

.mab30 {
  margin-bottom: 30px!important;
}

.mab40 {
  margin-bottom: 40px!important;
}

.mab1em {
  margin-bottom: 1em!important;
}
.mab15em {
  margin-bottom: 1.5em!important;
}
.mab2em {
  margin-bottom: 2em!important;
}
.mab25em {
  margin-bottom: 2.5em!important;
}
.mab3em {
  margin-bottom: 3em!important;
}
.mab35em {
  margin-bottom: 3.5em!important;
}
.mab4em {
  margin-bottom: 4em!important;
}
.mab45em {
  margin-bottom: 4.5em!important;
}
.mab5em {
  margin-bottom: 5em!important;
}


.mat0 {
  margin-top: 0!important;
}
.mat5 {
  margin-top: 5px!important;
}
.mat10 {
  margin-top: 10px!important;
}
.mat20 {
  margin-top: 20px!important;
}
.mat30 {
  margin-top: 30px!important;
}
.mat1em {
  margin-top: 1em!important;
}
.mat15em {
  margin-top: 1.5em!important;
}
.mat2em {
  margin-top: 2em!important;
}
.mat25em {
  margin-top: 2.5em!important;
}
.mat3em {
  margin-top: 3em!important;
}
.mat35em {
  margin-top: 35em!important;
}


.mat-10 {
  margin-top: -10px!important;
}

.mat-20 {
  margin-top: -20px!important;
}

.mat-30 {
  margin-top: -30px!important;
}

.mar5 {
  margin-right: 5px!important;
}

.mar10 {
  margin-right: 10px!important;
}

.mar20 {
  margin-right: 20px!important;
}

.mal10 {
  margin-left: 10px!important;
}

.mal20 {
  margin-left: 20px!important;
}

.pa0 {
  padding: 0!important;
}

.pat0 {
  padding-top: 0!important;
}

.pab0 {
  padding-bottom: 0!important;
}

.pat15 {
  padding-top: 15px!important;
}

/*font size*/

.font_xs {
  font-size: 0.8em;
}

.font_sm {
  font-size: 0.9em;
}

.font_la {
  font-size: 1.2em;
}

.font_xl {
  font-size: 1.4em;
}

.font_normal {
  font-weight: normal !important;
}

.font_bold {
  font-weight: bold !important;
}

.text_center {
  text-align: center!important;
}

.text_right {
  text-align: right!important;
}

.text_left {
  text-align: left!important;
}

.font_0 {
  font-size: 0;
}

.font_plus {
  font-size: 1.07em;
}

/*kerning*/

.kerning0 {
  letter-spacing: 0;
}

.kerning {
  letter-spacing: -0.05em;
}

.kerning01 {
  letter-spacing: -0.1em;
}

.kerning02 {
  letter-spacing: -0.25em;
}

.kerning03 {
  letter-spacing: -0.5em;
}

.kerning_plus {
  letter-spacing: 0.25em;
}


/*行間詰め*/

.line_height_min {
  line-height: 1.6;
}

/*文字間*/

.letter {
  letter-spacing: 0.08em;
}

/*タイトル調整用*/

.kerning_tit {
  letter-spacing: 0.27em;
}

.kerning_tit_m {
  letter-spacing: 0.17em;
}

.kerning_tit_m02 {
  letter-spacing: 0.1em;
}

.font_num {
  font-size: 1.05em;
}

/*文字にアンチエイリアスを効かせる*/
.font_smoothing{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 文章枠用CSS
====================================================================================================================================== */

p,
.common_box .text_box {
  margin-bottom: 1.5em;
}

.list_box p {
  margin-bottom: 0;
}

.list_box img {
  max-width: none;
}

/*スタンダード（画像右／左寄せ）*/
.common_box {
  display: flow-root;
  overflow: visible;
  .img_box {
    float: left;
    margin-right: 20px;
    max-width: 48%;
    width: 100%;
    margin-top: 0.5em;
    img{
      width: 100%;
    }
  }
  .text_box {
    display: inline;
    overflow: hidden;
  }
  /*右*/
  &.right{
    .img_box{
      float: right;
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

/*画像3つ並び*/
.photo_3column_in {
  margin: 1.5em 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5%;
  .image_wrap{
    img{
      width: 100%;
      height: auto;
    }
  }
}

/*画像2つ並び*/
.photo_2column_in {
  margin: 1.5em 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5%;
  .image_wrap{
    img{
      width: 100%;
      height: auto;
    }
  }
}

/* ２列入力枠 */
.two_column_box{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5em;
  .half_box{
    width: 48.5%;
  }
}

details{
  border: {
    top: 1px solid $origin_color;
    bottom: 1px solid $origin_color;
  }
  margin-bottom: 2em;
  summary{
    font-size: 1.275rem;
    font-weight: bold;
    padding: 1.7em 1.4em;
    padding-right: 3em;
    position: relative;
    cursor: pointer;
    &:before, &:after{
      content: "";
      display: block;
      width: 1em;
      height: 1.5px;
      background-color: $origin_color;
      position: absolute;
      right: 1.6em;
      top: 50%;
    }
    &:after{
      rotate: 90deg;
    }
    p{
      margin-bottom: 0;
    }
  }
  &[open]{
    summary{
      &:after{
        display: none;
      }
    }
  }
  .qa_answer{
    padding: 0 1.7em 2em;
    p{
      margin-bottom: 0;
    }
  }
}

@media (hover: hover) {
  details summary:hover{
    opacity: 0.7;
  }
}

#conts .qualified_personnel_list{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  li{
    padding: 0;
    margin: 0 0 -1px;
    &:before{
      display: none;
    }
  }
  dl{
    display: flex;
    justify-content: space-between;
    padding: 1.2em 1em;
    border: {
      top: 1px solid $origin_color;
      bottom: 1px solid $origin_color;
    }
  }
}

#conts .certification_list{
  display: flex;
  gap: 30px;
  li{
    width: 200px;
    padding: 0;
    margin: 0;
    &:before{
      display: none;
    }
  }
}

#message_president{
  border: 1px solid $origin_color;
  margin-bottom: 4em;
  .title_area{
    position: relative;
    padding: 125px 60px 60px;
    .photo_president{
      width: 420px;
      position: absolute;
      top: -50px;
      right: 60px;
      border: 1px solid $origin_color;
      img{
        width: 100%;
      }
    }
    h2{
      width: 605px;
      position: relative;
      padding: 0 0 0 12px;
      margin-bottom: -43px;
      border: none;
      line-height: 1;
      img{
        width: 100%;
        height: auto;
      }
    }
    p{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
      font-size: 1.9125rem;
      font-weight: bold;
      line-height: 1;
      gap: 0.35em;
      span{
        color: #fff;
        background-color: $blue;
        padding: 0.4em 0.6em;
      }
    }
  }
  .main_sentence{
    border-top: 1px solid $origin_color;
    padding: 60px;
    > p{
      margin-bottom: 2.3em;
    }
    .sentence_end{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1.3em;
      font-size: 0.9563rem;
      font-weight: bold;
      margin-bottom: 0;
      .president_name{
        width: 180px;
        img{
          width: 100%;
        }
      }
    }
  }
}

.section_member_data{
  margin-bottom: 7.5em;
}
#conts .member_data_list{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: {
    top: 1px solid $origin_color;
    left: 1px solid $origin_color;
  }
  li{
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 1.7;
    border: {
      right: 1px solid $origin_color;
      bottom: 1px solid $origin_color;
    }
    padding: 60px 50px 50px;
    p{
      margin-bottom: 0.8em;
    }
    .illust{
      img{
        width: 100%;
      }
    }
    &:before{
      top: 0;
      left: 0;
      translate: 0;
      width: 2.45em;
    }
    &:nth-child(-n + 9) {
      &:before{
        content: "0" counter(ordered_num);
      }
    }
  }
}

.interview_link{
  border: 1px solid $origin_color;
  margin-bottom: 2.3em;
  a{
    display: block;
    text-decoration: none;
  }
  .interview_intro{
    align-items: flex-start;
    .text_area{
      padding-top: 3.8em;
    }
  }
}
@media (hover: hover) {
  .interview_link{
    a:hover{
      opacity: 0.7;
      h2{
        text-decoration: underline;
      }
    }
  }
}

.interview_intro{
  position: relative;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .num{
    position: absolute;
    top: 0;
    left: 0;
    font-family: $font_teko;
    font-size: 1.275rem;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1;
    margin: 0;
    width: 2.45em;
    aspect-ratio: 1;
    background-color: $origin_color;
    color: #fff;
    display: grid;
    place-items: center;
  }
  .photo_area{
    aspect-ratio: 1;
    width: 220px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid $origin_color;
    }
    .fukidashi{
      position: absolute;
      top: -10px;
      left: calc(100% - 75px);
      margin: 0;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1;
      white-space: nowrap;
      padding: 1.1em 1.6em;
      border-radius: 2em;
      &:after{
        content: "";
        display: block;
        width: 0.9375em;
        aspect-ratio: 15/13;
        position: absolute;
        top: 100%;
        left: 4.5em;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: contain;
      }
      &.fukidashi_blue{
        background-color: $blue;
        &:after{
          background-image: url(../images/page/recruit/interview/fukidashi_blue.svg);
        }
      }
      &.fukidashi_pink{
        background-color: $pink;
        &:after{
          background-image: url(../images/page/recruit/interview/fukidashi_pink.svg);
        }
      }
      &.fukidashi_yellow{
        background-color: $yellow;
        &:after{
          background-image: url(../images/page/recruit/interview/fukidashi_yellow.svg);
        }
      }
    }
  }
  .text_area{
    width: calc(100% - 220px - 45px);
    letter-spacing: 0.05em;
    h2{
      font-size: 2.125rem;
      line-height: 1.6;
      padding: 0;
      border: none;
      margin-bottom: 0;
    }
    p{
      font-weight: bold;
      line-height: 1.7;
      margin-bottom: 0;
    }
  }
}
#interview_wrap{
  border: 1px solid $origin_color;
  margin-bottom: 2em;
  .interview_intro{
    border-bottom: 1px solid $origin_color;
  }
  .interview_detail{
    padding: 60px 50px 50px;
    p{
      margin-bottom: 3em;
    }
    .section_box{
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
  .interview_photo{
    text-align: center;
    img{
      width: unquote('min(800px, 100%)');
    }
  }
}

.service_list_wrap{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 4.7em;
  .service_box{
    border: 1px solid $origin_color;
    a{
      display: block;
      font-weight: normal;
      text-decoration: none;
    }
    .thumb{
      aspect-ratio: 485/288;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text_area{
      padding: 35px;
      font-size: 0.9563rem;
      p{
        margin-bottom: 0;
      }
      .link_text{
        font-size: 1.7rem;
        font-weight: bold;
        line-height: 1.6;
        margin-bottom: 0.2em;
        &:after{
          content: "";
          display: inline-block;
          width: 0.8em;
          aspect-ratio: 1;
          background: url(../images/icon_external_link.svg) no-repeat left center;
          background-size: contain;
          margin-left: 0.25em;
        }
      }
    }
  }
}
@media (hover: hover) {
  .service_list_wrap{
    .service_box{
      a:hover{
        opacity: 0.7;
        .text_area{
          .link_text{
            text-decoration: underline;
          }
        }
      }
      
    }
  }
}

.contact_wrap{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid $origin_color;
  .contact_box{
    font-size: 1.275rem;
    font-weight: bold;
    line-height: 1;
    a{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      height: 12em;
      padding-top: 13.3%;
    }
    p{
      margin-bottom: 1.2em;
    }
    address{
      padding-left: 1.08em;
      padding-right: 0.4em;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 0.78em auto;
      font-family: $font_teko;
      font-size: 2.5em;
      font-weight: 500;
      letter-spacing: 0.01em;
      span{
        display: block;
        position: relative;
        &:after{
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
        }
      }
    }
    &.contact_mail{
      address{
        background-image: url(../images/icon_mail.svg);
      }
    }
    &.contact_tel{
      address{
        background-image: url(../images/icon_tel.svg);
      }
    }
    .uketsuke{
      font-size: 0.75em;
      margin: 1em 0 0;
    }
    &:first-of-type{
      border-right: 1px solid $origin_color;
    }
  }
}

@media (hover: hover) {
  .contact_wrap{
    .contact_box{
      a:hover{
        background-color: #f5f5f5;
        address{
          span{
            &:after{
              background-color: $origin_color;
            }
          }
        }
      }
    }
  }
}

.salary_list{
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 1em;
}


@media screen and (max-width: 768px) {

  /* PC、スマホ振り分け */
  .sp, .tb{
    display: block !important;
  }
  .sp_off{
    display: none !important;
  }


  /*---------------------------------------------------------------*/
  /*default heading*/

  h1.page_title {
    font-size: 2.125rem;
    padding: 1.8em 25px 0.8em;
  }
  h2{
    font-size: 1.625rem;
    &.member_data_title{
      font-size: 1.2rem;
      .num{
        font-size: 7.7em;
      }
      .en{
        font-size: 2em;
      }
    }
  }
  h3{
    &.interview_index{
      font-size: 1.4rem;
    }
  }

  /* table */
  table{
    font-size: 0.9em;
  }

  /*索引*/
  .list_box{
    a{
      .title_area{
        background-size: 1.1em auto;
        padding-right: 1.5em;
      }
    }
    .border_box{
      padding: 30px;
      gap: 20px;
    }
    .icon_area {
      width: 130px;
    }
    .title_area{
      width: calc(100% - 130px - 20px);
      .title{
        font-size: 1.25rem;
      }
    }
    .comment{
      font-size: 0.9rem;
    }
  }

  /*画像*/
  .wide_photo_image {
    margin-top: 1.4em;
  }
  .wide_photo_image.in {
    margin: 1em 0 2em;
  }
  .wide_photo_image .caption {
    font-size: 0.7em;
  }

  /*container*/
  .section_box {
    padding: 0;
  }
  

  /*戻る*/
  a.btn.back,
  .btn.back a {
    min-width: 30%;
    background-size: auto 13px;
  }

  /* ２列ボタン */
  .button_2_column{
    .btn{
      width: 49%;
      margin-bottom: 1em;
    }
  }


  /*icon*/
  .btn_icon {
    padding: 2px 0 2px 22px;
    background-size: 10px auto;
    margin-top: 0.6em;
  }
  .btn_icon.back {
    background-size: 8px auto;
  }
  /*wide*/
  a.btn_wide {
    padding: 0.6em 10px;
    font-size: 1.4em;
    letter-spacing: 0.1em;
  }

  #conts .article_list{
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    li{
      padding: 6em 0 2.2em;
    }
  }

  /*3列ボックス*/
  .box_3_column{
    margin: 0 -0.6% 1.5em;

    .box{
      padding: 0 0.6%;
      margin-bottom: 1.5em;
    }
  }
  /*2列ボックス*/
  .box_2_column{
    overflow: hidden;
    margin: 0 -0.6% 1.5em;

    .box{
      padding: 0 0.6%;
      margin-bottom: 1.5em;
    }
  }
  
  details{
    summary{
      font-size: 1.2rem;
      padding: 1.75em 1em;
      padding-right: 2.2em;
      &:before, &:after{
        width: 0.9em;
        right: 0.9em;
      }
    }
    .qa_answer{
      padding: 0 1.15em 2.5em;
    }
  }

  .point_box{
    padding: 2em 2.2em 1.8em;
  }
  
  #conts .qualified_personnel_list{
    grid-column-gap: 20px;
    dl{
      padding: 0.9em 0.5em;
    }
  }
    
  #message_president{
    .title_area{
      position: relative;
      padding: 130px 40px 40px;
      .photo_president{
        width: 360px;
        position: absolute;
        top: -50px;
        right: 40px;
        border: 1px solid $origin_color;
        img{
          width: 100%;
        }
      }
      h2{
        width: unquote('min(490px, 100%)');
        margin-bottom: -33px;
      }
      p{
        font-size: 1.5rem;
        gap: 0.3em;
      }
    }
    .main_sentence{
      padding: 40px;
      > p{
        margin-bottom: 2em;
      }
      .sentence_end{
        .president_name{
          width: 170px;
        }
      }
    }
  }
  
  #conts .member_data_list{
    li{
      font-size: 1.375rem;
      padding: 55px 35px 35px;
    }
  }

  .interview_link{
    margin-bottom: 2em;
  }
  .interview_intro{
    padding: 40px;
    .photo_area{
      width: 180px;
      .fukidashi{
        top: -12px;
        left: calc(100% - 65px);
        font-size: 0.8125rem;
      }
    }
    .text_area{
      width: calc(100% - 180px - 25px);
      h2{
        font-size: 2rem;
      }
    }
  }
  #interview_wrap{
    .interview_detail{
      padding: 40px;
    }
  }

  .service_list_wrap{
    gap: 20px;
    .service_box{
      .text_area{
        padding: 25px;
        .link_text{
          font-size: 1.5rem;
        }
      }
    }
  }

  .contact_wrap{
    .contact_box{
      font-size: 2.3vw;
    }
  }
}

@media screen and (max-width: 540px) {

  /* PC、スマホ振り分け */
  .tb, .pc_tb{
    display: none !important;
  }
  .sp_only{
    display: block !important;
  }

  /*--------------------------------------------------*/
  /*default heading*/

  h1.page_title {
    font-size: 2rem;
    padding-top: 2.1em;
  }
  h2{
    &.member_data_title{
      font-size: 1.0625rem;
      gap: 1.4em;
    }
  }
  
  /*news list*/
  #conts .article_list{
    display: block;
    li{
      padding-top: 5em;
    }
  }

  /* テーブル */
  table{
    th{
      white-space: normal;
    }
    th,td{
      padding: 12px;
    }
    tbody{
      th{
        width: auto;
        min-width: 7em;
        max-width: 10em;
      }
    }
  }
  /* table */
  .scroll_table{
    overflow: scroll;
    table{
      min-width: 150vw;
      th{
        white-space: nowrap;
      }
    }
  }

  /* 文章枠 */
  .common_box{
    &,&.right{
      .img_box{
        max-width: initial;
        float: none;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .left_img_box,
  .right_img_box{
    float: none;
    max-width: initial;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }


  /*索引*/
  .list_box {
    a{
      .title_area{
        background-image: url(../images/icon_arrow_black_r.svg);
      }
    }
    .border_box{
      padding-right: 20px;
    }
    .icon_area {
      width: 100px;
    }

    .title_area {
      width: calc(100% - 100px - 20px);
    }
  }

  /*2列ボックス*/
  .list_box{
    &.listbox_2_column,
    &.listbox_3_column{
      display: block;
      width: auto;
      margin-left: 0;
      margin-right: 0;
      .icon_area{
        width: 90px;
      }
      .text_area{
        width: calc(100% - 90px);
      }
    }
  }



  /* ボタン */
  .button_area{
    flex-direction: column;
  }
  a.btn,
  button.btn,
  .btn a,
  #myreset,
  form input.submit_btn {
    display: block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  
    + a,
    + input{
      margin-top: 0.5em;
      margin-left: auto;
    }
  }
  
  /* ２列ボタン */
  .button_2_column{
    display: block;

    .btn{
      width: 100%;
      margin-bottom: 0.5em;
    }
  }

  /*3列ボックス*/
  .box_3_column{
    margin: 0 0 1.5em;

    .box{
      float: none;
      width: 100%;
      max-width: 280px;
      padding: 0;
      margin:0 auto 1.5em;
    }
  }
  /*2列ボックス*/
  .box_2_column{
    overflow: hidden;
    margin: 0 0 1.5em;

    .box{
      float: none;
      width: 100%;
      padding: 0;
      margin-bottom: 1.5em;
    }
  }

  .color_box{
    padding: 23px 25px;
  }

  .acc{
    margin-bottom: 25px;
    .m{
      font-size: 1.1em;
      padding: 20px 40px 20px 15px;
      background-position: right 12px center;
      background-size: 25px auto;
    }
    .t{
      padding: 15px;
    }
  }

  #conts .qualified_personnel_list{
    display: block;
  }
  
  #conts .certification_list{
    gap: 6%;
    li{
      width: 47%;
    }
  }

  #message_president{
    .title_area{
      position: static;
      padding: 30px;
      .photo_president{
        width: unquote('max(220px, 65%)');
        position: static;
        margin: -85px auto -5px;
      }
      h2{
        margin-bottom: -17px;
        padding-left: 5px;
      }
      p{
        font-size: 4vw;
      }
    }
    .main_sentence{
      padding: 30px;
      .sentence_end{
        font-size: 0.9rem;
        gap: 1.2em;
        .president_name{
          width: 120px;
        }
      }
    }
  }
  
  #conts .member_data_list{
    display: block;
    li{
      font-size: 4.8vw;
      padding: 50px 30px 30px;
    }
  }

  .interview_link{
    margin-bottom: 1.7em;
    .interview_intro{
      .text_area{
        padding-top: 2em;
      }
    }
  }
  .interview_intro{
    padding: 30px;
    .num{
      font-size: 1.2rem;
      width: 2.38em;
    }
    .photo_area{
      width: 100px;
      .fukidashi{
        top: -12px;
        left: calc(100% - 65px);
        font-size: 0.7125rem;
        &:after{
          left: 7.2em;
        }
      }
    }
    .text_area{
      width: calc(100% - 100px - 15px);
      h2{
        font-size: 1.4375rem;
      }
    }
  }

  #interview_wrap{
    .interview_detail{
      padding: 30px;
    }
  }

  .service_list_wrap{
    display: block;
    margin-bottom: 4em;
    .service_box{
      margin-bottom: 20px;
    }
  }

  .contact_wrap{
    display: block;
    .contact_box{
      font-size: 4.3vw;
      a{
        height: auto;
        padding: 2.6em 0;
      }
      &:first-of-type{
        border-right: none;
        border-bottom: 1px solid $origin_color;
      }
    }
  }
}
