$origin_color: #0B0C0D;
$link_color: $origin_color;
$hover_color: $origin_color;
$red: #c02;
$blue: #00AEDE;
$gray: #F5F5F5;
$pink: #FF9A6E;
$yellow: #EBD027;

$zindex_max: 2147483647;

$font_teko: "Teko", sans-serif;